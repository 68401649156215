var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"css":false},on:{"leave":_vm.leave}},[_c('div',{staticClass:"locations"},[_c('div',{staticClass:"locations__line"}),_c('div',{staticClass:"locations__breadcrumb"}),_c('div',{staticClass:"locations__brandheader"},[_c('Brand-header',{ref:"BrandHeader",attrs:{"link":'/',"mountedAnim":true}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.les),expression:"!les"}],staticClass:"locations__sites-brooklyn",on:{"click":function($event){return _vm.initLocation(true)}}},[_c('Img-studio',{ref:"imgBrooklyn",attrs:{"loading":_vm.loading.x,"align":'left',"imgUrl":'https://res.cloudinary.com/micasastudios/image/upload/v1616976874/WEBPAGE/STUDIO/BKLYN/STUDIO/BKLYNSTDIO_2_eqymfp.jpg'},nativeOn:{"mouseenter":function($event){return _vm.initLoading(true)},"mouseleave":function($event){return _vm.backLoading(true)}}})],1),_c('div',{staticClass:"locations__sites-brooklyn-menu"},[_c('Menu-studio',{ref:"menuBrooklyn",attrs:{"isBrooklyn":true,"textButton":_vm.hoodBrooklyn ? 'Studio' : _vm.dataComp.buttonN,"btnUpdate":_vm.brooklyn},on:{"clickButton":function($event){_vm.hoodBrooklyn = !_vm.hoodBrooklyn}}})],1),(_vm.les)?_c('div',{staticClass:"locations__sites-les-content"},[_c('div',{staticClass:"locations__sites-les-exit",on:{"click":_vm.closeLes}},[_c('Close-button',{ref:"lesClose",attrs:{"mountedAnim":true,"mountedDelay":1,"color":'cyan'}})],1),_c('div',{staticClass:"locations__sites-les-paragraph"},[_c('Paragraph',{ref:"lesParagraph",attrs:{"align":'right',"title":_vm.hoodLes
              ? _vm.dataComp.les.infoHoodLes.title
              : _vm.dataComp.les.infoStudioLes.title,"subtitle":_vm.hoodLes
              ? _vm.dataComp.les.infoHoodLes.subtitle
              : _vm.dataComp.les.infoStudioLes.subtitle,"subtitle2":_vm.hoodLes
              ? _vm.dataComp.les.infoHoodLes.subtitle2
              : _vm.dataComp.les.infoStudioLes.subtitle2,"text":_vm.hoodLes
              ? _vm.dataComp.les.infoHoodLes.text
              : _vm.dataComp.les.infoStudioLes.text,"color":'cyan',"mountedAnim":true}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"locations__sites-les-button"},[_c('Button',{ref:"lesButton",attrs:{"mountedAnim":true,"mountedDelay":2,"text":'Book Now',"color":'cyan'}})],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.brooklyn),expression:"!brooklyn"}],staticClass:"locations__sites-les",on:{"click":function($event){return _vm.initLocation(false)}}},[_c('Img-studio',{ref:"imgLes",attrs:{"loading":_vm.loading2.x,"align":'right',"imgUrl":'https://res.cloudinary.com/micasastudios/image/upload/v1616977957/WEBPAGE/STUDIO/LES/STUDIO/LES_STUDIO_maqebq.jpg'},nativeOn:{"mouseenter":function($event){return _vm.initLoading(false)},"mouseleave":function($event){return _vm.backLoading(false)}}})],1),_c('div',{staticClass:"locations__sites-les-menu"},[_c('Menu-studio',{ref:"menuLes",attrs:{"textButton":_vm.hoodLes ? 'Studio' : _vm.dataComp.buttonN,"color":'cyan',"btnUpdate":_vm.les},on:{"clickButton":function($event){_vm.hoodLes = !_vm.hoodLes}}})],1),(_vm.brooklyn)?_c('div',{staticClass:"locations__sites-brooklyn-content"},[_c('div',{staticClass:"locations__sites-brooklyn-exit",on:{"click":_vm.closeBrooklyn}},[_c('Close-button',{ref:"brooklynClose",attrs:{"mountedAnim":true,"mountedDelay":1,"color":'cyan'}})],1),_c('div',{staticClass:"locations__sites-brooklyn-paragraph"},[_c('Paragraph',{ref:"brooklynParagraph",attrs:{"title":_vm.hoodBrooklyn
              ? _vm.dataComp.bro.infoHoodBrooklyn.title
              : _vm.dataComp.bro.infoStudioBrooklyn.title,"subtitle":_vm.hoodBrooklyn
              ? _vm.dataComp.bro.infoHoodBrooklyn.subtitle
              : _vm.dataComp.bro.infoStudioBrooklyn.subtitle,"subtitle2":_vm.hoodBrooklyn
              ? _vm.dataComp.bro.infoHoodBrooklyn.subtitle2
              : _vm.dataComp.bro.infoStudioBrooklyn.subtitle2,"text":_vm.hoodBrooklyn
              ? _vm.dataComp.bro.infoHoodBrooklyn.text
              : _vm.dataComp.bro.infoStudioBrooklyn.text,"mountedAnim":true,"color":'cyan'}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"locations__sites-brooklyn-button"},[_c('Button',{ref:"brooklynButton",attrs:{"mountedAnim":true,"mountedDelay":2,"text":'Book Now',"color":'cyan'}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }